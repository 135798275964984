/**
* Created by tudou on 2020/4/11 20:49.
*/
<template>
  <a-layout-header class="app-header">
    <div class="app-header__message mr-10">
      <p class="text-ellipsis">
        <app-bread-crumb></app-bread-crumb>
      </p>
    </div>
    <div class="app-header__action">
      <!--<div class="app-header__action&#45;&#45;item">-->
        <!--<a-badge :count="0">-->
          <!--<span class="action__notice">-->
            <!--<i-icon type="icon-msg"></i-icon> {{$t('app.todo')}}-->
          <!--</span>-->
        <!--</a-badge>-->
      <!--</div>-->
      <div class="app-header__action--item">
        <a-dropdown>
          <a class="ant-dropdown-link">
            <a-avatar
              size="small"
              shape="circle"
              icon="user"/>
            {{userInfo.userDTO.userName}} <i-icon type="icon-menu-down"></i-icon>
          </a>
          <a-menu slot="overlay" @click="handleAction">
            <a-menu-item-group key="g1">
              <!--<a-menu-item key="info">-->
                <!--<i-icon class="mr-10" type="icon-user"></i-icon>-->
                <!--<span>我的资料</span>-->
              <!--</a-menu-item>-->
              <a-menu-item key="pwd">
                <i-icon class="mr-10" type="icon-pwd"></i-icon>
                <span>修改密码</span>
              </a-menu-item>
            </a-menu-item-group>
            <a-menu-item-group>
              <a-menu-item key="qrcodeShop">
                <a-icon class="mr-10" type="qrcode" />
                <span>小程序二维码</span>
              </a-menu-item>
            </a-menu-item-group>
            <a-menu-item-group>
              <a-menu-item key="logout">
              <i-icon class="mr-10" type="icon-exit"></i-icon>
              <span>退出登录</span>
              </a-menu-item>
            </a-menu-item-group>
          </a-menu>
        </a-dropdown>
      </div>
      <!--<div class="app-header__action&#45;&#45;item">-->
        <!--<a-radio-group @change="handleLocaleChange" size="small" defaultValue="zh_CN">-->
          <!--<a-radio-button value="zh_CN">中文</a-radio-button>-->
          <!--<a-radio-button value="en_US">English</a-radio-button>-->
        <!--</a-radio-group>-->
      <!--</div>-->
    </div>
    <!-- 修改密码弹出层 开始-->
    <a-modal
      title="修改密码"
      :visible="changePwdVisible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      @ok="submitForm('ruleForm')"
      @cancel="handleCancel">
      <a-form-model ref="ruleForm" :model="ruleForm" :rules="rules" v-bind="layout">
        <a-form-model-item label="原密码" prop="password">
          <a-input v-model="ruleForm.password" type="password"  />
        </a-form-model-item>
        <a-form-model-item label="新密码" prop="newPassword">
          <a-input v-model="ruleForm.newPassword" type="password" autocomplete="off" />
        </a-form-model-item>
        <a-form-model-item label="确认新密码" prop="checkPass">
          <a-input v-model="ruleForm.checkPass" type="password" autocomplete="off" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 修改密码弹出层 结束-->
    <!-- 门店二维码 开始 -->
    <a-modal
      title="门店小程序二维码"
      :visible="qrCodeVisible"
      :maskClosable="false"
      cancelText="关闭"
      okText="下载"
      @ok="downloadQrCode()"
      @cancel="qrCodeVisible = false">
      <div class="qrcode-wrap">
        <img class="qrcode" :src="qrCodeUrl" alt="" id="qrcode">
      </div>
    </a-modal>
    <!-- 门店二维码 结束 -->
  </a-layout-header>
</template>

<script>
import AppBreadCrumb from './AppBreadCrumb.vue';
import { sysUserUpdatepwd } from '@/api/user';
import { getLocal } from '../../libs/utils';
import { isDev } from '../../libs/config';

export default {
  name: 'AppHeader',
  components: {
    AppBreadCrumb,
  },
  props: {},
  data() {
    let validatePass = (rule, value, callback) => {
      if (this.ruleForm.checkPass && value !== this.ruleForm.checkPass) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (this.ruleForm.newPassword && value !== this.ruleForm.newPassword) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    };
    return {
      qrCodeUrl: '',
      qrCodeVisible: false,
      changePwdVisible: false,
      confirmLoading: false,
      ruleForm: {
        password: '',
        newPassword: '',
        checkPass: '',
      },
      rules: {
        password: [{ required: true, message: '请输入原密码', trigger: 'change' }],
        newPassword: [{ required: true, message: '请输入新密码', }, { validator: validatePass, trigger: 'blur' }],
        checkPass: [{ required: true, message: '请输入确认新密码', }, { validator: validatePass2, trigger: 'blur' }],
      },
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
      },
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.getUserInfo;
    }
  },
  watch: {},
  created() {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    handleLocaleChange(e) {
      const locale = e.target.value;
      this.$i18n.locale = locale;
      this.$store.commit('setLocale', locale);
    },
    info() {
    },
    pwd() {
      this.changePwdVisible = true;
    },
    qrcodeShop() {
      this.qrCodeVisible = true;
      const storeInfo = getLocal('storeInfo');
      const url = window.location.protocol + '//' + window.location.host;
      const devUrl = '/api/api/qrcode/shop';
      // const devUrl = '/api/api/benbenqrcode/shop';
      const prodUrl = storeInfo.wxQrcodePath;
      const params = `?shopId=${storeInfo.shopId}&shopName=${storeInfo.shopName}`;

      this.qrCodeUrl = url + (isDev ? devUrl : prodUrl) + params;
      console.log(this.qrCodeUrl);
    },
    logout() {
      this.$store.commit('setToken', '');
      this.$store.commit('setUserInfo', '');

      window.location.reload();
    },
    handleAction(item) {
      this[item.key]();
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const params = {
            oldPassword: this.ruleForm.password,
            password: this.ruleForm.newPassword,
            password1: this.ruleForm.checkPass,
            userId: this.userInfo.userDTO.userId,
          };
          sysUserUpdatepwd(params).then(data => {
            this.$message.success('更改成功，请重新登录');
            this.handleCancel();
            setTimeout(() => {
              this.logout();
            }, 1000);
          });

        } else {
          return false;
        }
      });
    },

    downloadQrCode() {
      const storeInfo = getLocal('storeInfo');
      const img = document.getElementById('qrcode');
      const url = img.src;
      const a = document.createElement('a');
      const event = new MouseEvent('click');
      a.download = `${storeInfo.shopName}-小程序二维码`;
      a.href = url;
      a.dispatchEvent(event);
      this.qrCodeVisible = false;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleCancel() {
      this.resetForm('ruleForm');
      this.changePwdVisible = false;
    },
  }
};
</script>

<style lang="less" scoped>
@import "../../style/modules/variables";

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  background-color: #fff;
  border-bottom: 1px solid @border-color-secondary;

  .app-header__message {
    flex: 1 1 auto;
    overflow: hidden;
  }
  .app-header__action {
    flex: 1 0 auto;
    display: flex;
    justify-content: flex-end;

    .app-header__action--item {
      margin-left: 20px;
    }
  }

  .action__notice {
    display: inline-block;
    padding: 5px;
  }
}
.qrcode-wrap {
  display: flex;
  justify-content: center;
}
.qrcode {
  width: 300px;
  height: 340px;
}
</style>
